@charset "UTF-8";

$web-font:'Aref Ruqaa', serif;
$font-android:'Noto Sans JP', sans-serif;
$font-family:"Hiragino Maru Gothic W4 JIS2004", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-default:1.6rem;
$line-height-default:1.6em;
$colorDefault:#333;
$pink:#FF816F;
$rightPink:#FFB3A9;
$green:#99DB9F;




@mixin opacity($opacity) {
     opacity: $opacity;
     $opacityIE: $opacity * 100;
     filter: alpha(opacity=$opacityIE);
}

@mixin default-width() {
	max-width:1200px;
	min-width:1000px;
	margin:0 auto;
}

// @mixin inner-fream {
// 	width: 780px;
// }


@mixin txtImage {
	overflow: hidden;
	text-indent: 150%;
	white-space: nowrap;
}

@mixin ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin absPosition ($top: auto, $right: auto, $bottom: auto, $left: auto) {
     position: absolute;
     top: $top;
     right: $right;
     bottom: $bottom;
     left: $left;
}

@mixin css3($property, $value) {
     @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
          #{$prefix}#{$property}: $value;
     }
}


@mixin huckclearfix($clearfix) {
	#{$clearfix} {
		display:inline-block;
		&:after {
			content:"";
			display:block;
			height:0;
			clear:both;
			visibility:hidden;
		}
	}
	/*\*/
	* html {
		& #{$clearfix} {
			height:1%;
		}
	}
	#{$clearfix} {
		display:block;
	}
	/**/
}

