@charset "UTF-8";
@import "import";

@import url(common.css);

/*
-----------------------------------------------------------------------
style
-----------------------------------------------------------------------
*/

/* index */
body#index main {
	.aboutRozafiArea {
		padding: 20px 0 40px 0;
		.mainTit01 {
			width: 230px;
		}
		.boxInner {
			margin: 60px 0 20px 0;
			padding: 10px;
			.box01 {
				h3 {
					width: 100%;
					margin: auto;
					height: 75px;
					@include txtImage;
					background: {
						image:url("../images/index/rozafi_txt01.png");
						repeat: no-repeat;
						size:contain;
					};
				}
				p {
					letter-spacing: 0.02em;
					margin: 10px 0 20px 0;
				}
			}
			.imgBox {
				margin: auto;
				display: block;
				width: 80%;
			}
		}
	}
}
body#index main,body.introduction-common main {
	.introdoctionArea {
		padding: 20px 0 40px 0;
		.mainTit01 {
			width: 260px;
		}
		.boxInner {
			margin: 80px 0 50px 0;
			letter-spacing: -.40em;
			.thumbnailLink {
				letter-spacing: normal;
				margin-bottom: 20px;
			}
		}
	}
}
body#index main,body.news-common main {
	.newsArea {
		padding: 20px 0 40px 0;
		.mainTit01 {
			width: 145px;
		}
		.boxInner {
			margin: 80px 0 50px 0;
			padding: 0 10px;
			letter-spacing: -.40em;
			.btnLine {
				margin-bottom: 10px;
			}
		}
	}
}
/* タブレットサイズ 768px以上 */
@media (min-width: 768px) {
body#index main {
	.aboutRozafiArea {
		padding: 50px 0 100px 0;
		.mainTit01 {
			width: 280px;
		}
		.boxInner {
			margin: 150px 80px 80px 80px;
			letter-spacing: -.40em;
			.box01,.imgBox {
				display: inline-block;
				letter-spacing: normal;
			}
			.box01 {
				h3 {
					width: 515px;
					height: 91px;
					margin-left: 0;
					margin-bottom: 30px;
					@include txtImage;
					background: {
						image:url("../images/index/rozafi_txt01.png");
						repeat: no-repeat;
					};
				}
				p {
					width: 540px;
				}
			}
			.imgBox {
				width: 318px;
				margin-left: 60px;
			}
		}
	}
}
body#index main {
	.introdoctionArea {
		padding: 50px 0 100px 0;
		.mainTit01 {
			width: 305px;
		}
		.boxInner {
			margin: 150px 0 50px 0;
			letter-spacing: -.40em;
			padding: 0 130px;
			.thumbnailLink {
				width: 300px;
				letter-spacing: normal;
				margin-bottom: 20px;
				&:nth-child(2),
				&:nth-child(5) {
					margin-right: 20px;
					margin-left: 20px;
				}
			}
		}
	}
}
body#index main {
	.newsArea {
		padding: 50px 0 100px 0;
		.mainTit01 {
			width: 170px;
		}
		.boxInner {
			margin: 150px 0 80px 0;
			.btnLine {
				margin-bottom: 20px;
				width: 840px;
			}
			.box01 {
				overflow: hidden;
			}
		}
	}
}
}



/* news */
@media (max-width: 767px) {
body.news-common main {
	.newsArea .boxInner {
		margin-top: 20px;
		margin-bottom: 10px;
	}
}
}
@media (min-width: 768px) {
body.news-common main {
	.newsArea {
		padding: 50px 0 100px 0;
		.mainTit01 {
			width: 170px;
		}
		.boxInner {
			display: inline-block;
			letter-spacing: -.40em;
			margin: 50px 0 80px 0;
			padding: 0 0 0 10px;
			.btnLine {
				margin-bottom: 20px;
				width: 880px;
				.txtArea {
					width: 595px;
				}
			}
			.box01 {
				overflow: hidden;
			}
			p.days {
				font-size: 1.6rem;
				span {
					color: #FFF;
					padding: 0 5px;
					margin-left: 5px;
				}
			}
		}
	}
}
}
body#news-post {
	.postArticle {
		background: rgba(255,255,255,0.8);
		width: 100%;
		padding: 10px 10px 10px 10px;
		margin-bottom: 40px;
		box-shadow:0 0 3px #DDD;
		border: 2px solid #FFFDF4;
		p.days span {
			background-color: $rightPink;
			color: #FFF;
			padding: 0 5px;
		}
		.newsTit01 {
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 120%;
			letter-spacing: 0.05em;
			border-bottom: 1px solid lighten( $rightPink, 10% );
			margin: 10px 0 15px 0;
			padding-bottom: 15px;
		}
		.postInner {
			font-size: 1.4rem;
			letter-spacing: normal;
			overflow: hidden;
			a {
				position: relative;
				display: inline-block;
				text-decoration: none;
				color: darken( $pink, 10% );
				&:after {
					content: "";
					width: 100%;
					height: 1px;
					background-color: lighten( $pink, 10% );
					@include absPosition(auto, auto, 0, 0);
					@include css3(transform, scaleX(1));
					@include css3(transition, 0.5s);
				}
				&:hover::after {
					@include css3(transform, scaleX(0));
				}
			}
			p {font-size: 1.4rem;}
			p,img {
				display: block;
				margin-bottom: 10px;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
@media (min-width: 768px) {
body#news-post {
	.postArticle {
		padding: 20px 20px 20px 20px;
		width: 880px;
		.newsTit01 {
			font-size: 2.6rem;
		}
		.newsTit01 {
			font-size: 2.0rem;
		}
		.postInner {
			p {font-size: 1.6rem;}
			font-size: 1.6rem;
			p,img {
				display: block;
				margin-bottom: 20px;
			}
			img {
				float: left;
				margin-right: 15px;
				max-width: 400px;
				width: auto;
			}
		}
	}
}
}
/* introduction */
@media (max-width: 767px) {
body.introduction-common main {
	.introdoctionArea .boxInner {
		margin-top: 20px;
	}
}
}
@media (min-width: 768px) {
body.introduction-common main {
	.introdoctionArea {
		padding: 50px 0 100px 0;
		.mainTit01 { width: 305px; }
		.boxInner {
			display: inline-block;
			margin: 50px 0 50px 0;
			width: 880px;
			letter-spacing: -.40em;
			.thumbnailLink {
				width: 280px;
				letter-spacing: normal;
				margin-bottom: 20px;
				.scaleImg {
					width: 260px;
					height: 260px;
				}
				&:nth-child(3n+2) {
					margin-right: 20px;
					margin-left: 20px;
				}
			}
		}
	}
}
}

@media (max-width: 767px)  {
body#introduction-post main {
	.postArea {
		margin-bottom: 50px;
		padding: 10px;
		.postImageArea {
			width: 100%;
			margin-bottom: 30px;
		}
		.postItemArea {
			width: 100%;
			float: right;
			margin-bottom: 30px;
			h1 {
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 22px;
				padding-bottom: 5px;
				border-bottom: 2px solid $rightPink;
				margin-bottom: 20px;
				letter-spacing: -0.01em;
			}
			dl {
				dt {
					font-size: 1.6rem;
					color: $rightPink;
					font-weight: 600;
					margin-bottom: 5px;
					letter-spacing: -0.01em;
				}
				dd {
					margin-bottom: 20px;
					letter-spacing: -0.01em;
					span{
						font-size: 2.0rem;
					}
				}
			}
			.moreBtn {
				width: 100%;
				letter-spacing: -0.01em;
				font-weight: 500;
				margin-bottom: 5px;
			}
		}
		.snsArea {
			clear: both;
		}
	}
}
}
@media (min-width: 768px) {
body#introduction-post main {
	.postArea {
		margin-bottom: 100px;
		background-color: rgba(255, 255, 255, 0.8);
		padding: 15px 10px;
		box-shadow: 0 0 3px #DDD;
		border: 2px solid #FFFDF4;
		.postImageArea {
			width: 500px;
			float: left;
			margin-bottom: 50px;
		}
		.postItemArea {
			width: 345px;
			float: right;
			margin-bottom: 50px;
			h1 {
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 22px;
				padding-bottom: 5px;
				border-bottom: 2px solid $rightPink;
				margin-bottom: 20px;
				letter-spacing: -0.01em;
			}
			dl {
				dt {
					font-size: 1.6rem;
					color: $rightPink;
					font-weight: 600;
					margin-bottom: 5px;
					letter-spacing: -0.01em;
				}
				dd {
					margin-bottom: 20px;
					letter-spacing: -0.01em;
					span{
						font-size: 2.0rem;
					}
				}
			}
			.moreBtn {
				width: 100%;
				letter-spacing: -0.01em;
				font-weight: 500;
				margin-bottom: 5px;
			}
		}
		.snsArea {
			clear: both;
		}
	}
}
}



/* contact */
body#contact main {
	.contactArea {
		padding: 20px 10px 60px;
		.mainTit01 {
			width: 200px;
		}
		.leadFaq {
			margin: 80px auto 50px;
			h2 {
				font-size: 1.6rem;
				text-align: left;
			}
			dl {
				margin: 20px 0 40px;
				dt {
					position: relative;
					padding-left: 50px;
					margin-bottom: 20px;
					&:before {
						content: "Q";
						background: $green;
						padding: 4px 0 0 11px;
					}
				}
				dd {
					position: relative;
					padding-left: 50px;
					margin-bottom: 30px;
					&:before {
						content: "A";
						background: $rightPink;
						padding: 5px 0 0 12px;
					}
				}
				dt:before,dd:before {
					font-family: $web-font;
					font-size: 2.2rem;
					color: #FFF;
					display: inline-block;
					vertical-align: middle;
					width: 40px;
					height: 40px;
					border-radius:50%;
					margin: auto;
					@include absPosition( 0, auto, 0, 0);
				}
			}
		}
		.contactform {
			h2 {
				font-size: 2.4rem;
				text-align: center;
				padding: 0 15px;
				letter-spacing: 0.1em;
				display: flex;
				align-items: center;
				margin-bottom: 30px;
				&:before, &:after {
					content: "";
					flex-grow: 1;
					height: 2px;
					margin-top: 5px;
				}
				&:before{
					margin-right: .3em;
					background:linear-gradient(right,$rightPink, #fFF);
				}
				&:after{
					margin-left: .3em;
					background:linear-gradient(left,$rightPink, #fFF);
				}
			}
			h3 {
				text-align: center;
				font-size: 1.6rem;
				color: $pink;
				font-weight: 100;
				margin-bottom: 20px;
			}
			form {
				dl {
					dt {font-weight: bold;
						margin-bottom: 10px;
						&:after {
							content: "任意";
							background: $green;
							color: #FFF;
							margin-left: 5px;
							padding: 0 5px;
							font-weight: 400;
						}
						&.required:after {
							content: "必須";
							background: $rightPink;
						}
					}
					dd {
						margin-bottom: 30px;
						input[type="text"],textarea {
							width: 100%;
							padding: 7px 10px;
							font-size: 1.4rem;
							font-family: $font-family;
							border: 1px solid #DDD;
							background: rgba(#FFF,0.8);
							transition: 0.3s;
							&:focus {
								border: 1px solid $rightPink;
								box-shadow: 0 0 3px $rightPink;
								background: #FFF;
							}
						}
						textarea {
							height: 200px;
						}
						.checkboxTxt {
							color: $rightPink;
							margin-bottom: 10px;
							font-weight: bold;
							font-size: 1.4rem;
						}
						label {
							display: block;
							position: relative;
							cursor: pointer;
							padding: 0 0 0 25px;
							line-height: 1;
							font-size: 1.6rem;
							margin-bottom: 15px;
							&:before{
								@include absPosition(50%, auto, auto, 0);
								width: 18px;
								height: 18px;
								content: "";
								margin-top: -10px;
								border: 1px solid $rightPink;
								transition: 0.3s;
							}
						}
						input[type="checkbox"] {
							display: none;
							&:checked + label:before { background-color: $rightPink; }
						}
					}
				}
				.btnArea {
					li {
						width: 100%;
						margin-top: 20px;
					}
					button {
						font-family: $font-family;
					}
				}
			}

		}
		#confirm {
			padding-top: 70px;
			h3 {
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 1.2rem;
				text-align: left;
			}
			dd {
				background: #FFF;
				padding: 5px 10px;
				min-height: 35px;
				border: 1px solid $rightPink;
			}
		}
		#thanks {
			padding-top: 50px;
			padding-bottom: 30px;
			h1 {
				text-align: center;
				font-size: 2.0rem;
				color: $pink;
			}
			.message {
				margin: 20px auto;
			}
		}
	}
}
/* タブレットサイズ 768px以上 */
@media (min-width: 768px) {
body#contact main {
	.contactArea {
		padding: 50px 0 40px 0;
		.mainTit01 {
			width: 240px;
		}
		.leadFaq {
			margin: 120px auto 100px;
			width: 950px;
			h2 {
				font-size: 2.4rem;
				text-align: center;
			}
			dl {
				margin: 40px 0 60px;
				dt {
					position: relative;
					padding-left: 55px;
					margin-bottom: 30px;
					&:before {
						content: "Q";
						background: $green;
						padding: 4px 0 0 11px;
					}
				}
				dd {
					position: relative;
					padding-left: 55px;
					margin-bottom: 40px;
					&:before {
						content: "A";
						background: $rightPink;
						padding: 5px 0 0 12px;
					}
				}
				dt:before,dd:before {
					font-family: $web-font;
					font-size: 3.0rem;
					color: #FFF;
					display: inline-block;
					vertical-align: middle;
					width: 45px;
					height: 45px;
					border-radius:50%;
					margin: auto;
					@include absPosition( 0, auto, 0, 0);
				}
			}
		}
		.contactform {
			h2 {
				font-size: 2.6rem;
				text-align: center;
				padding: 0 15px;
				letter-spacing: 0.1em;
				display: flex;
				align-items: center;
				margin-bottom: 50px;
				&:before, &:after {
					content: "";
					flex-grow: 1;
					height: 2px;
					margin-top: 5px;
				}
				&:before{
					margin-right: .3em;
					background:linear-gradient(right,$rightPink, #fFF);
				}
				&:after{
					margin-left: .3em;
					background:linear-gradient(left,$rightPink, #fFF);
				}
			}
			h3 {
				text-align: center;
				font-size: 2.4rem;
				color: $pink;
				font-weight: 100;
				margin-bottom: 20px;
			}
			form {
				width: 930px;
				margin: 0 auto 100px;
				p.error {
					color: #ff0000;
					font-size: 1.4rem;
					margin-top: 10px;
					&:before {
						content: "※";
					}
				}
				dl {
					dt {font-weight: bold;
						margin-bottom: 10px;
						&:after {
							content: "任意";
							background: $green;
							color: #FFF;
							margin-left: 5px;
							padding: 0 5px;
							font-weight: 400;
						}
						&.required:after {
							content: "必須";
							background: $rightPink;
						}
					}
					dd {
						margin-bottom: 30px;
						input[type="text"],textarea {
							width: 100%;
							padding: 7px 10px;
							font-size: 1.4rem;
							font-family: $font-family;
							border: 1px solid #DDD;
							background: rgba(#FFF,0.8);
							transition: 0.3s;
							&:focus {
								border: 1px solid $rightPink;
								box-shadow: 0 0 3px $rightPink;
								background: #FFF;
							}
						}
						textarea {
							height: 200px;
						}
						.checkboxTxt {
							color: $rightPink;
							margin-bottom: 10px;
							font-weight: bold;
							font-size: 1.4rem;
						}
						label {
							display: block;
							position: relative;
							cursor: pointer;
							padding: 0 0 0 25px;
							line-height: 1;
							font-size: 1.6rem;
							margin-bottom: 15px;
							&:before{
								@include absPosition(50%, auto, auto, 0);
								width: 18px;
								height: 18px;
								content: "";
								margin-top: -10px;
								border: 1px solid $rightPink;
								transition: 0.3s;
							}
						}
						input[type="checkbox"] {
							display: none;
							&:checked + label:before { background-color: $rightPink; }
						}
					}
				}
				.btnArea {
					letter-spacing: -.4em;
					text-align: center;
					margin-top: 50px;
					li {
						width: 300px;
						display: inline-block;
						letter-spacing: normal;
						margin: 0 10px;
					}
					button {
						font-family: $font-family;
					}
				}
			}

		}
		#confirm {
			padding-top: 150px;
			h3 {
				margin-bottom: 50px;
			}
			dd {
				background: #FFF;
				padding: 10px;
				border: 1px solid $rightPink;
			}
		}
		#thanks {
			padding-top: 80px;
			padding-bottom: 80px;
			h1 {
				text-align: center;
				font-size: 2.4rem;
				color: $pink;
			}
			.message {
				width: 700px;
				margin: 50px auto;
			}
		}
	}
}
}

/*
----------------------------------------
huckclearfix
----------------------------------------
*/

@include huckclearfix('
	.clearbox
');
